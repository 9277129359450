import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/dropdown";

export async function getDropdownsByType(request) {
  return await ApiService.post(url + "/getAllOfType", request as never).then(
      (res) => {
      const data = res.data;
          return data;
      }
  )
}

export async function addDropdown(dropdown) {
  return await ApiService.post(url+"/add", dropdown).catch(
    ({ response }) => {
      processErrors(response);
    }
  );
}

export async function addDefaultDropdowns() {
    return await ApiService.get(url + "/addDefaults").catch(
        ({ response }) => {
            processErrors(response);
        }
    );
}

export async function updateDropdown(dropdown) {
  return await ApiService.post(url+"/update", dropdown).catch(
    ({ response }) => {
      processErrors(response);
    }
  );
}

export async function getList(request) {
  return await ApiService.post(url + "/getList", request as never).then(
      (res) => {
      const data = res.data;
          return data;
      }
  )
}
