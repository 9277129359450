
  import { defineComponent, onMounted, ref, reactive } from "vue";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import useVuelidate from "@vuelidate/core";
  import { maxLength, required, helpers } from "@vuelidate/validators";
  import { getDictionary } from "@/api/dictionaries.api";
  import { getOrganization } from "@/core/services/JwtService";
  import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
  import useFilters from "@/modules/common/useFilters";
  import { addDropdown, addDefaultDropdowns, updateDropdown, getDropdownsByType} from "@/api/dropdown.api"
  import PaginationUi from "@/components/ABilling/Pagination.vue";
  
  
  export interface Pagination {
    currentPage: number;
    totalPages: number;
    totalCount: number;
    pageSize: number;
  }
  
  export interface Data {
    items: [];
    dropdownsList: [];
    keyword: string;
    createNewMode: boolean;
    orderBy: string;
    isDecr: boolean;
    panel: any;
    pagination: Pagination;
    IsAdvancedOrderBy: boolean;
    isActive: boolean;
    inUse: boolean;
    isDefault: boolean;
    saving: boolean;
    isDirty: boolean,
    isLoaded: boolean,
    dropdown?: any,
    dict: {
      types: [];
      locTypes: string[];
      boolList: { code?: boolean | null; name: string }[];
      creditDebitList: { code?: string|null; name: string }[];
    };
    showInactive: boolean;
  }
  export default defineComponent({
    name: "Dropdown",
    components: { PaginationUi },
    setup() {
      const {
        orderList,
      } = useFilters();
      orderList.value = [
       {
            name: " ",
            key: " ",
            isFilter: false,
            keyword: null,
        },
        {
          name: "Name",
          key: "value",
          isFilter: true,
          keyword: null,
        },
        {
          name: "Description",
          key: "caption",
          isFilter: true,
          keyword: null,
        },
        {
          name: "Active",
          key: "isActive",
          isFilter: true,
          keyword: null,
        },
        {
          name: "In Use",
          key: "inUse",
          isFilter: true,
          keyword: null,
        },
      ];
  
      let organizationId = ref<string | null>("");
  
      let data = reactive<Data>({
        items: [],
        isDirty: false,
        isLoaded: false,
        isDefault: false,
        saving: false,
        dropdown: undefined,
        dropdownsList: [],
        keyword: "",
        orderBy: "value",
        IsAdvancedOrderBy: false,
        createNewMode: true,
        isDecr: false,
        showInactive: false,
        pagination: {
          currentPage: 1,
          totalPages: 0,
          totalCount: 0,
          pageSize: 50,
        },
        isActive: false,
        inUse: true,
        panel: {},
        dict: {
          types: [],
          locTypes: ["Inpatient","Attendance Based","Outpatient"],
          boolList: [
            { code: true, name: "Yes" },
            { code: false, name: "No" },
            { code: null, name: "" },
          ],
          creditDebitList: [
            { code: "true", name: "Credit" },
            { code: "false", name: "Debit" },
            { code: "null", name: "" },
          ],
        },
      });
  
      onMounted(async () => {
        organizationId.value = getOrganization();
        data.createNewMode = true;
        data.dropdown = {
          "type": "",
          "value": "",
          "caption": "",
          "configuration": "",
          "isActive": true,
          "inUse": true,
        }
        data.dict.types = await getDictionary(15, "");
        await addDefaults();
        setCurrentPageTitle("Dropdowns");
      });
  
      async function addToDropdownList(){
        data.saving = true;
        var dropdown = {
          "type": data.dropdown.type,
          "value": data.dropdown.value.trim(),
          "caption": data.dropdown.caption,
          "configuration": getConfigForDatabase(data.dropdown),
          "isActive": data.dropdown.isActive,
          "inUse": data.dropdown.inUse,
        }
  
        // if duplicate detected, display message
        if (duplicateDetected(data.dropdown.value, data.dropdown.id, data.dropdownsList)) {
            // method for displaying modal
            displayDuplicateModal();
        }
        else {
          // if not duplicate, save and update list
          var result;
          if (data.dropdown.type == 'Level Of Care') {
              result = await loc$.value.dropdown.$validate();
          }
          else if (data.dropdown.type == 'Adjustment Types') {
              result = await adjustmentType$.value.dropdown.$validate();
          }
          else result = await nonLoc$.value.dropdown.$validate();
          if (result) {
              data.dropdown.id = await addDropdown(dropdown);
              clear();
              getListForType();
          }
        }
        data.saving = false;
      }
  
      async function clear(){
          data.dropdown.value = "";
          data.dropdown.caption = "";
          data.dropdown.isDefault = false;
          data.dropdown.configuration = "";
          data.dropdown.locType = "";
          data.dropdown.defaultLocType = "";
          data.dropdown.isActive = true;
          data.dropdown.inUse = true;
          data.dropdown.isCredit = undefined;
          loc$.value.$reset();
          nonLoc$.value.$reset();
          adjustmentType$.value.$reset();
          data.createNewMode = true;
      }
  
      function displayDuplicateModal() {
          Swal.fire({
              title: "Duplicate Entry. Please enter a different value for the Name field.",
              confirmButtonText: "Go Back",
              allowOutsideClick: false,
              customClass: {
                  cancelButton: "order-1",
              },
          });
      }
  
      async function clearConfirm(){
        if(!(data.dropdown.value=="" && data.dropdown.caption=="")){
            Swal.fire({
              title: "Are you sure you want to leave without saving changes?",
              showDenyButton: true,
              showCancelButton: true,
              confirmButtonText: "Yes",
              denyButtonText: "Cancel",
              allowOutsideClick: false,
              customClass: {
                  actions: "my-actions",
                  cancelButton: "order-2",
                  confirmButton: "order-1",
              },
            }).then((result) => {
                if (result.isConfirmed) {
                    data.isDefault = false;
                    getListForType();
                }
            });
        }
        else {
          data.dropdown.type = "";
          getListForType();
          clear();
        }
      }
  
      function updateLOCConfigForDisplaying(list) {
        list.data.forEach((element) => {
            if (element.configuration != '') {
                var obj = JSON.parse(element.configuration);
                element.configuration = obj.locType;
                element.isDefault = obj.isDefault;
                if (element.isDefault == 'true') {
                    element.defaultLocType = obj.locType;
                    element.defaultValue = element.value;
                }
            }
        })
        return list.data;
      }
  
      function updateAdjustmentTypeConfigForDisplaying(list) {
            list.data.forEach((element) => {
            if (element.configuration != '') {
                var obj = JSON.parse(element.configuration);
                element.configuration = obj.isCredit;
                element.isDefault = obj.isDefault;
                if (element.isDefault == 'true') {
                    element.defaultIsCredit = obj.isCredit;
                    element.defaultValue = element.value;
                }
            }
        })
        return list.data;
      }
  
      function updateNonLocConfig(list) {
            list.data.forEach((element) => {
                if (element.configuration != '') {
                    var obj = JSON.parse(element.configuration);
                    element.isDefault = obj.isDefault;
                    element.defaultValue = element.value;
                }
            })
            return list.data;
        }
  
      function getConfigForDatabase(dropdown) {
          if (dropdown.isDefault == 'true') {
              if (dropdown.type == 'Level Of Care') return '{"locType":' + '"' + dropdown.defaultLocType + '","isDefault":"' + dropdown.isDefault + '"}';
              else if (dropdown.type == 'Adjustment Types') return '{"isCredit":"' + dropdown.defaultIsCredit + '","isDefault":"' + dropdown.isDefault + '"}';
              else if (dropdown.type == 'Billing Indicator' || dropdown.type == 'Notes' || dropdown.type == 'Account Status' || dropdown.type == 'Reason') return '{"isDefault":"' + dropdown.isDefault + '"}';
          }
          else {
              if (dropdown.type == 'Level Of Care') return '{"locType":' + '"' + dropdown.locType + '","isDefault":"' + dropdown.isDefault + '"}';
              else if (dropdown.type == 'Adjustment Types') return '{"isCredit":"' + dropdown.isCredit + '","isDefault":"' + dropdown.isDefault + '"}';
              else if (dropdown.type == 'Billing Indicator' || dropdown.type == 'Notes' || dropdown.type == 'Account Status' || dropdown.type == 'Reason') return '{"isDefault":"' + dropdown.isDefault + '"}';
          }
      }
  
      async function getListForTypeDefaultOrder() {
          data.orderBy = "value";
          getListForType();
      }
  
      async function getListForType() {
          data.isDefault = false;
          let order = data.orderBy;
          if (data.isDecr) {
              order = order + " Desc";
          }
  
          let orderBy: string[] | null = null;
          orderBy = [order];
          if (data.dropdown.type == 'Level Of Care') {
              orderList.value = [
                  {
                      name: " ",
                      key: " ",
                      isFilter: false,
                      keyword: null,
                  },
                  {
                      name: "Name",
                      key: "value",
                      isFilter: true,
                      keyword: null,
                  },
                  {
                      name: "LOC Preferred Name",
                      key: "caption",
                      isFilter: true,
                      keyword: null,
                  },
                  {
                      name: "LOC Type",
                      key: "configuration",
                      isFilter: true,
                      keyword: null,
                  },
                  {
                      name: "Active",
                      key: "isActive",
                      isFilter: true,
                      keyword: null,
                  },
                  {
                      name: "In Use",
                      key: "inUse",
                      isFilter: true,
                      keyword: null,
                  },
              ];
          }
          else if (data.dropdown.type == 'Account Status' || data.dropdown.type == 'Reason' || data.dropdown.type == 'Notes') {
              orderList.value = [
                  {
                      name: " ",
                      key: " ",
                      isFilter: false,
                      keyword: null,
                  },
                  {
                      name: "Name",
                      key: "value",
                      isFilter: true,
                      keyword: null,
                  },
                  {
                      name: "Description",
                      key: "caption",
                      isFilter: true,
                      keyword: null,
                  },
                  {
                      name: "Active",
                      key: "isActive",
                      isFilter: true,
                      keyword: null,
                  },
                  {
                      name: "In Use",
                      key: "inUse",
                      isFilter: true,
                      keyword: null,
                  },
              ];
          }
          else if (data.dropdown.type == 'Adjustment Types') {
              orderList.value = [
                  {
                      name: " ",
                      key: " ",
                      isFilter: false,
                      keyword: null,
                  },
                  {
                      name: "Name",
                      key: "value",
                      isFilter: true,
                      keyword: null,
                  },
                  {
                      name: "Description",
                      key: "caption",
                      isFilter: true,
                      keyword: null,
                  },
                 /*  {
                      name: "Transaction Type",
                      key: "configuration",
                      isFilter: true,
                      keyword: null,
                  }, */
                  {
                      name: "Active",
                      key: "isActive",
                      isFilter: true,
                      keyword: null,
                  },
                  {
                      name: "In Use",
                      key: "inUse",
                      isFilter: true,
                      keyword: null,
                  },
              ];
          }
        let request = {
            type: data.dropdown.type,
            orderBy: orderBy,
            pageNumber: data.pagination.currentPage,
            pageSize: data.pagination.pageSize,
        };
  
        const res = await getDropdownsByType(request);
        if(data.dropdown.type=='Level Of Care'){
           data.dropdownsList = updateLOCConfigForDisplaying(res);
        }
        else if (data.dropdown.type == 'Adjustment Types') {
           data.dropdownsList = updateAdjustmentTypeConfigForDisplaying(res);
        }
        else data.dropdownsList = updateNonLocConfig(res);
  
        data.pagination.currentPage = res.currentPage;
        data.pagination.totalPages = res.totalPages;
        data.pagination.totalCount = res.totalCount;
        data.pagination.pageSize = res.pageSize;
        //sort defaults from non defaults
        var list = data.dropdownsList;
        data.dropdownsList = [];
        list.forEach((element) => {
            if (element['isDefault']== "true") data.dropdownsList.push(element);
        })
        list.forEach((element) => {
            if (element['isDefault']== "false") data.dropdownsList.push(element);
        })
        clear();
      }
  
      async function addDefaults() {
          addDefaultDropdowns();
      }
  
      async function updateDropdownInList() {
        data.saving = true;
        var dropdown = {};
        if (data.dropdown.isDefault=='true') {
          dropdown = {
            "id": data.dropdown.id,
            "type": data.dropdown.type,
            "value": data.dropdown.defaultValue,
            "caption": data.dropdown.caption,
            "configuration": getConfigForDatabase(data.dropdown),
            "isActive": data.dropdown.isActive,
            "inUse": data.dropdown.inUse,
            }
        }
        else {
            dropdown = {
                "id": data.dropdown.id,
                "type": data.dropdown.type,
                "value": data.dropdown.value,
                "caption": data.dropdown.caption,
                "configuration": getConfigForDatabase(data.dropdown),
                "isActive": data.dropdown.isActive,
                "inUse": data.dropdown.inUse,
            }
        }
  
        if (duplicateDetected(data.dropdown.value, data.dropdown.id, data.dropdownsList)) {
          // method for displaying modal
          displayDuplicateModal();
        }
        else {
          var result;
          if(data.dropdown.type=='Level Of Care'){
          result = await loc$.value.dropdown.$validate();
          }
          if(data.dropdown.type == 'Adjustment Types'){
          result = await adjustmentType$.value.dropdown.$validate();
          }
          else result = await nonLoc$.value.dropdown.$validate();
          if (result) {
          data.dropdown.id = await updateDropdown(dropdown);
          getListForType();
          }
        }
        data.saving = false;
  
      }
  
      function selectItem(item) {
        data.dropdown.id = item.id;
        data.dropdown.type = item.type;
        data.dropdown.value = item.value;
        data.dropdown.caption = item.caption;
        data.dropdown.isDefault = item.isDefault;
        data.isDefault = item.isDefault;
  
        data.dropdown.defaultLocType = item.configuration;
        data.dropdown.locType = item.configuration;
  
        data.dropdown.defaultIsCredit = item.configuration;
        data.dropdown.isCredit = item.configuration;
        data.dropdown.defaultLocType = item.configuration;
        data.dropdown.defaultValue = item.defaultValue;
        data.dropdown.isActive = item.isActive;
        data.createNewMode = false;
      }
  
      function boolToString(booleanFromData) {
          var item = data.dict.boolList.find((element) => { return element.code == booleanFromData });
          if (item != null) {
              return item.name;
          }
      }
  
      function boolToCreditOrDebit(booleanFromData) {
          var item = data.dict.creditDebitList.find((element) => { return element.code == booleanFromData });
          if (item != null) {
              return item.name;
          }
      }
  
      function duplicateDetected(value, id, list) {
          var found = false;
          value = value.trim();
          if (!data.createNewMode) {
              list.forEach((element) => {
                  element.value = element.value.trim();
                  if (value.toUpperCase() == element.value.toUpperCase() && element.id!=id) found = true;
              })
          }
          else {
              list.forEach((element) => {
                  if (value.toUpperCase() == element.value.toUpperCase()) found = true;
              })
          }
          return found;
      }
  
      async function selectFilter(header) {
          if (!header.key || !header.isFilter) {
              return;
          }
          if (data.orderBy == header.key) {
              data.isDecr = !data.isDecr;
          } else {
              data.isDecr = false;
          }
          data.orderBy = header.key;
          await getListForType();
      }
  
      function getSortInfo(key) {
        if (data.orderBy == key) return true;
        return false;
      }
  
      async function pageChanged(page){
        data.pagination.currentPage = page;
        await getListForType();
      }
  
      async function pageSizeChanged(pageSize) {
        data.pagination.pageSize = pageSize;
        data.pagination.currentPage = 1;
        await getListForType();
      }
  
      const nonLocDropdownRules = {
        dropdown: {
          type: { required: helpers.withMessage('Required', required) },
          value: { required: helpers.withMessage('Required', required) , maxLength: maxLength(50) },
          caption: {maxLength: maxLength(50)},
          isActive: {required}
        },
      };
  
      const locDropdownRules = {
        dropdown: {
          type: { required:helpers.withMessage("Required", required) },
          value: { required: helpers.withMessage('Required', required) , maxLength: maxLength(50) },
          caption: {maxLength: maxLength(50)},
          isActive: {required},
          locType: {required: helpers.withMessage('Required', required) }
        },
      };
  
      const adjustmentTypeDropdownRules = {
        dropdown: {
          type: { required: helpers.withMessage('Required', required)  },
          value: { required: helpers.withMessage('Required', required) , maxLength: maxLength(50) },
          caption: {maxLength: maxLength(50)},
          isActive: {required},
        //   isCredit: {required: helpers.withMessage('Required', required) }
        },
      };
  
      let nonLoc$ = useVuelidate(nonLocDropdownRules, data as never, {
            $autoDirty: false,
      });
      let loc$ = useVuelidate(locDropdownRules, data as never, {
            $autoDirty: false,
      });
      let adjustmentType$ = useVuelidate(adjustmentTypeDropdownRules, data as never, {
            $autoDirty: false,
      });
  
      return {
        addToDropdownList,
        addDefaults,
        duplicateDetected,
        updateLOCConfigForDisplaying,
        updateAdjustmentTypeConfigForDisplaying,
        getConfigForDatabase,
        updateNonLocConfig,
        updateDropdownInList,
        clear,
        clearConfirm,
        organizationId,
        data,
        getSortInfo,
        selectItem,
        boolToString,
        boolToCreditOrDebit,
        orderList,
        getListForType,
        getListForTypeDefaultOrder,
        selectFilter,
        loc$,
        nonLoc$,
        adjustmentType$,
        pageChanged,
        pageSizeChanged,
      };
    },
  });
  